import AffiliateLinkPulldown from "../components/AffiliateLinkPulldown.vue";
import IterableMultiSubscriptionForm from "../components/forms/IterableMultiSubscriptionForm.vue";
import IterableListSubscriptionForm from "../components/forms/IterableListSubscriptionForm.vue";
import SearchBox from "../components/global/SearchBox.vue";
import vClickOutside from "v-click-outside";
import store from "../store.js";
import Http from "../classes/global/Http.js";
import PopupModal from "../components/layouts/PopupModal.vue";
import Vue from "vue";
import Cookies from "js-cookie";
import Notification from "../components/global/Notification.vue";
import validationMixin from "../mixins/validation-support";
import Fieldify from "../components/Fieldify.vue";
import GlobalBanner from "../components/GlobalBanner.vue";
import SmsOptInForm from "../components/forms/SmsOptInForm.vue";
import ReviewPopup from "../components/reviews/ReviewPopup.vue";

var layoutHeaderApp = new Vue({
  el: "#site-navigation",
  name: "Header",
  store,

  directives: {
    clickOutside: vClickOutside.directive,
  },

  mixins: [validationMixin],

  components: {
    AffiliateLinkPulldown,
    SearchBox,
    PopupModal,
    ReviewPopup,
    IterableMultiSubscriptionForm,
    Fieldify,
    GlobalBanner,
    SmsOptInForm
  },

  data: {
    mobileMenuOpen: false,
    accountMenuOpen: false,
    cartQuantity: 0,
    showReviewPopup: false,
    showEmailPopup: false,
    showEmailInput: true,
    optedInToSmsTexts: false,
    popUpEmailAddress: "",
    country: country,
    restrictions: restrictions,
    isYotpoEnabled: isYotpoEnabled,
    unreviewedProducts: []
  },

  mounted() {
    if (!window.sessionStorage.getItem("cartQuantity")) {
      Http.get("/cart?format=json").then((response) => {
        let cartQty = 0;
        if (response.lines) {
          response.lines.forEach((line) => (cartQty += line.quantity));
        }
        this.$store.commit("setCartQuantity", cartQty);
      });
    }
    this.updateCartQuantity();

    // Review & email popup modals
    if(!isYotpoEnabled && this.isLoggedIn && !Cookies.get("hide-review-popup")) {
        Http.get("/products/unreviewed-products")
            .then(response => {
                this.unreviewedProducts = response;
            });

        this.$refs.reviewModal.open();
    }
    else {
      this.openEmailPopup();
    }
  },

  computed: {
    mobileMenuIcon() {
      return this.mobileMenuOpen ? "fa-times" : "fa-bars";
    },



  },

  methods: {

    updateCartQuantity() {
      this.cartQuantity = window.sessionStorage.getItem("cartQuantity");
      setTimeout(this.updateCartQuantity, 500);
    },


    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },

    toggleAccountMenu() {
      this.accountMenuOpen = !this.accountMenuOpen;
    },

    closeAccountMenu(event) {
      const isAvatarClick = event.target.parentElement && event.target.parentElement.className === "avatar";
      if (this.accountMenuOpen && !isAvatarClick) {
        this.accountMenuOpen = false;
      }
    },


    openEmailPopup() {
      this.showEmailPopup = !this.isLoggedIn && !Cookies.get("hide-email-popup");
    },

    closeReviewPopup(){
      this.showReviewPopup = false;
      Cookies.set("hide-review-popup", "1", {expires: 3});
    },

    closeEmailPopup(event){
      this.showEmailPopup = false;
      Cookies.set("hide-email-popup", "1", {expires: 365});
      if(event?.target?.id === "take-five") {
        window.location.replace(`/take-5-daily`);
      }
    },

    optedInToSms(){
        this.optedInToSmsTexts = true;
    },

    onEmailSubmit(email) {
       if (email) {
         this.popUpEmailAddress = email;
        this.showEmailInput = false;
        this.$refs.communicationSignUpModal.$el.focus();
      }
    },
  },
});

var layoutFooterApp = new Vue({
  el: "#site-footer",
  name: "Footer",
  store,


  components: {
    "notification": Notification,
    IterableListSubscriptionForm,
      PopupModal, SmsOptInForm, ReviewPopup
  },

  data: {
    locale: pageLocale || "en_US",
    countries: activeCountries,
    restrictions: restrictions,
    website: websiteUrl,
    showSmsFooterLinkPopup: false,
    optedForSms: false,
      country: country,
  },

  computed: {
    selectedCountry() {
      return this.countries.find((c) => c.locale === this.locale);
    },
    sortedCountries() {
      const countries = [this.selectedCountry];
      this.countries.forEach(next => {
        if (next.locale !== this.locale) countries.push(next)
      });
      return countries;
    },

      showSmsFooterLink(){
        //don't show if they're not logged in
        return this.isLoggedIn;
      }
  },
  methods: {

    getFlagPath(country) {
      return this.$cdn(`cdn://images/layout/flags/${country.toLowerCase()}.png`);
    },

    selectNext(options) {
      let focusOnNext = false;
      let focusFound = false;
      let target = null;
      options.forEach(next => {
        if (focusOnNext) {
          target = next;
          focusOnNext = false;
          focusFound = true;
        } else if (next === document.activeElement) {
          focusOnNext = true;
        }
      })

      if (!focusFound) {
        target = options[0];
      }

      setTimeout(() => target.focus(), 100);
    },
    nextCountry(event) {
      const options = Array.from(document.getElementsByClassName("country-option"));
      this.selectNext(options);
      event.stopPropagation();
    },
    previousCountry(event) {
      const options = Array.from(document.getElementsByClassName("country-option")).reverse();
      this.selectNext(options);
      event.stopPropagation();
    },
    toggleDropdown(event) {
      const options = Array.from(document.getElementsByClassName("country-option"));
      let focusFound = false;
      options.forEach(next => {
        if (next === document.activeElement) {
          next.click();
          focusFound = true;
        }
      })
      if (!focusFound) {
        this.$refs.countrySelect.toggle();
        options[0].focus();
      }
      event.stopPropagation();
    },

    onFooterEmailSubmit(success){
      if(success){
        this.$buefy.notification.open({ duration: 5000, type: 'is-success', position: 'is-bottom-right', message: "<span role='alert' aria-live='polite'>Thank you! Keep an eye on your inbox!</span>" })
      }
      else{
        this.$buefy.notification.open({ duration: 5000, type: 'is-danger', position: 'is-bottom-right', message: "<span role='alert' aria-live='assertive'>Unable to add email.</span>" })
      }
    },

      optedInFromFooterLink(){
        this.optedForSms = true;
      },

      showSmsFooterLinkModal(val) {
          this.showSmsFooterLinkPopup = val;
      },
  },
  watch:{
    locale(val, oldVal) {
      window.location.search = "locale=" + this.locale;
    },
  },

});


